
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";
import { defineComponent, computed } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import store from "../../store";

export default defineComponent({
  name: "topbar",
  components: {
    KTNotificationsMenu,
    KTUserMenu,
  },
  methods: {
    showNotifications() {
      this.store.dispatch(Actions.LOAD_NOTIFICATIONS);
      this.store.dispatch(Actions.UPDATE_NOTIFICATIONS_STATUS);
    },
  },
  computed: {
    totalNotifications() {
      if (store.getters.getNotifications)
        return store.getters.getNotifications.size;
      return 0;
      //return store.getters.getNotifications.length
    },
  },


  setup() {
    const store = useStore();
    const name = computed(() => {
      if (store.getters.currentAuthUser.nome)
        return store.getters.currentAuthUser.nome;

      return "Utlizador";
    });

    return {
      name,
      store,
    };
  },
});
