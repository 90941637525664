
import { defineComponent } from "vue";
import { getIllustrationsPath } from "@/core/helpers/assets";
import store from "@/store";

export default defineComponent({
  name: "notifications-menu",
  components: {},
  data () {
    return {
      notificationList : Array
    }

  },
  computed : {
    totalNotifications() {
      if(store.getters.getNotifications)
        return store.getters.getNotifications.size
      return 0
      //return store.getters.getNotifications.length
    },
    notifications() {
      return store.getters.getNotifications
    }
  },
  methods : {
    loadNotifications() {
      this.notificationList = store.getters.getNotifications
    }
  },


  
});
