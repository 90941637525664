
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { useStore } from "vuex";
import store from "../../store";

export default defineComponent({
  name: "kt-menu",
  components: {},
  computed: {
    cartBadgeProductsCount() {
      if (!store.getters.getOrder || !store.getters.getOrder.Mov_Encomenda_Lin || store.getters.getOrder.Mov_Encomenda_Lin.length < 1) return 0;
      return store.getters.getOrder.Mov_Encomenda_Lin.length;
    },
    menuConfig() {
      return store.getters.currentAuthUser.acessoAreaFinanceira && store.getters.currentAuthUser.acessoAreaComercial ? MainMenuConfig : store.getters.currentAuthUser.acessoAreaFinanceira ? [MainMenuConfig[0], MainMenuConfig[2]] : store.getters.currentAuthUser.acessoAreaComercial ? 
      [{ heading: MainMenuConfig[1].heading, pages: [{
        heading: "Faturação",
        route: "/faturas",
        svgIcon: "media/icons/duotune/finance/fin007.svg",
      }, ...MainMenuConfig[1].pages]},
       MainMenuConfig[2]] : MainMenuConfig;
    },
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    const store = useStore();

    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
      store,
    };
  },
});
