const DocMenuConfig = [
  {
    heading: "Área Financeira",
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
      },
      {
        heading: "Faturação",
        route: "/faturas",
        svgIcon: "media/icons/duotune/finance/fin007.svg",
      },
    
    ],
  },
  {
    heading: "Área Comercial",
    pages: [    

      {
        heading: "Encomendas",
        route: "/encomendas",
        svgIcon: "media/icons/duotune/ecommerce/ecm001.svg",
      },

      {
        heading: "Nova Encomenda",
        route: "/neworder",
        svgIcon: "media/icons/duotune/ecommerce/ecm006.svg",
      },

      {
        sectionTitle: "Catálogo",
        route: "/products",
        svgIcon: "media/icons/duotune/ecommerce/ecm009.svg",
        sub: [
          {
            heading: "Produtos",
            route: "/products",
          },
          {
            heading: "Manuais",
            route: "/products/manuals",
          },

        ],
      },

      {
        heading: "Produtos Adquiridos",
        route: "/clientProducts",
        svgIcon: "media/icons/duotune/files/fil002.svg",
      },
      {
        heading: "Incidências",
        route: "/incidencias",
        svgIcon: "media/icons/duotune/general/gen044.svg",
      },

    
    ],
  },
  {
    heading: "Área Pessoal",
    pages: [
      {
        heading: "Obras",
        route: "/userDocs",
        svgIcon: "media/icons/duotune/files/fil012.svg",
      },
    ],
  }
];

export default DocMenuConfig;
