import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73f8a9ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = {
  class: "d-flex align-items-center ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_3 = {
  class: "d-flex align-items-center ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_4 = {
  class: "cursor-pointer symbol symbol-30px symbol-md-30px btn btn-icon btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_5 = { class: "svg-icon svg-icon-1" }
const _hoisted_6 = {
  key: 0,
  class: "d-flex align-items-center ms-1 ms-lg-3"
}
const _hoisted_7 = { class: "svg-icon svg-icon-1 badge-notification" }
const _hoisted_8 = {
  key: 0,
  class: "icon-button__badge btn-active-light-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!
  const _component_KTNotificationsMenu = _resolveComponent("KTNotificationsMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, [
        _createTextVNode("Olá, "),
        _createElementVNode("b", null, _toDisplayString(_ctx.name), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_inline_svg, { src: "media/misc/user3_icon.svg" })
        ])
      ]),
      _createVNode(_component_KTUserMenu)
    ]),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", {
            class: "cursor-pointer symbol symbol-30px symbol-md-30px btn btn-icon btn-active-light-primary btn-notification",
            "data-kt-menu-trigger": "click",
            "data-kt-menu-attach": "parent",
            "data-kt-menu-placement": "bottom-end",
            "data-kt-menu-flip": "bottom",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showNotifications && _ctx.showNotifications(...args)))
          }, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg, { src: "media/misc/alert_icon.svg" }),
              (_ctx.totalNotifications)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.totalNotifications), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createVNode(_component_KTNotificationsMenu)
        ]))
      : _createCommentVNode("", true)
  ]))
}